////POLICE
@font-face {
    font-family: "mont-reg";
    src: url("../fonts/Montserrat/Montserrat-Regular.eot");
    src: url("../fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "mont-bold";
    src: url("../fonts/Montserrat/Montserrat-Bold.eot");
    src: url("../fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat/Montserrat-Bold.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "volkhov";
    src: url("../fonts/Volkhov/Volkhov-Regular.eot");
    src: url("../fonts/Volkhov/Volkhov-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Volkhov/Volkhov-Regular.woff2") format("woff2"), url("../fonts/Volkhov/Volkhov-Regular.woff") format("woff"), url("../fonts/Volkhov/Volkhov-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


/*  ****************  Variables ********************/

$primaryColor: #fff;
$secondaryColor: #002a3a;
$accentColor: #f8eee3;
$darkColor: #000;
@mixin page-title {
    font-size: 5vh;
    @media only screen and (min-width: 480px) {
        font-size: 8vh;
    }
}

$title: 4vh;
$section-title: 3vh;
$text: 16px;
$primaryFont: "volkhov";
$secondaryFont: "mont-reg";
$secondaryBold: "mont-bold";
.text {
    font-size: $text;
    letter-spacing: 1.4px;
    font-family: $primaryFont;
    text-align: center;
    line-height: 3;
}

body {
    overflow-x: hidden;
}

h1 {
    @include page-title;
    text-transform: uppercase;
    font-family: $primaryFont;
}

p {
    @extend .text;
}

a {
    cursor: pointer;
    &:visited {
        color: inherit;
    }
}

section {
    height: auto;
}


/*  ****************  Header ********************/

nav {
    background: $primaryColor;
    padding-left: 15px;
    padding-right: 15px;
    height: 10vh;
    svg {
        width: 40px;
        height: 40px;
    }
    .insta-link {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $accentColor;
        border-radius: 50%;
    }
    .instagram-logo {
        width: 20px;
        height: 20px;
    }
    .langue {
        width: 40px;
        height: 40px;
        font-family: $secondaryBold;
        color: $darkColor;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        border-radius: 50%;
        margin-right: 20px;
        &:hover {
            background-color: $secondaryColor;
            color: $primaryColor;
            text-decoration: none;
            transition: 0.3s;
        }
    }
    .sociaux-header {}
}


/*  ****************  Entete ********************/

.entete {
    padding-left: 15px;
    padding-right: 15px;
}

.image-accueil {
    background-image: url(../img/cafe-latte.jpg);
    background-size: cover;
    background-position: center;
    height: 90vh;
    img {
        width: 60%;
        height: auto;
    }
}


/*  ****************  Origines ********************/

.origines {
    max-width: 90%;
    margin: 0 auto;
    .page-title {
        margin-bottom: 5vh;
        text-align: center;
        position: relative;
        z-index: 1;
    }
    svg {
        position: relative;
        top: 6vh;
    }
}

.marque {
    text-transform: uppercase;
    margin: 10vh 0;
    position: relative;
    z-index: 1;
    line-height: 1.4;
    font-size: 1.6vh;
    width: 30vh;
    height: 5vh;
    background-repeat: no-repeat;
}

.marque:lang(fr) {
    background-image: url(../img/Madame_Henrietta.svg);
}

.marque:lang(en) {
    background-image: url(../img/mrs_henrietta.svg);
}

.nom {
    position: relative;
    padding-right: 10px;
    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -5px;
        height: 36px;
        width: 2px;
        background-color: #888;
    }
}

.lot {
    font-size: 1.4vh;
    position: relative;
    padding-left: 10px;
    margin-left: 2px;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -5px;
        height: 36px;
        width: 2px;
        background-color: $darkColor;
    }
}

.numero {
    font-size: 2.2vh;
    margin-left: 10px;
}


/*  ****************  Newletter ********************/

.newsletter {
    background-color: $secondaryColor;
}

.col-cow {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        height: 95vh;
        top: 5vh;
        left: -55vw;
        width: 80vh;
        background-image: url(../img/vache.svg);
        background-size: cover;
    }
}

.col-newsletter {
    height: 60vh;
    h2 {
        color: $primaryColor;
        font-size: $title;
        font-family: $primaryFont;
        line-height: 1.2;
        padding: 0 15px;
    }
    form {
        margin-top: 5vh;
    }
    #mc_embed_signup_scroll {
        display: flex;
        max-width: 98%;
        margin: 0 auto;
    }
    #mc_embed_signup .clear {
        width: 100%;
    }
    .form-control::placeholder {
        font-family: $secondaryFont;
        color: $secondaryColor;
        text-align: center;
    }
    .input-group {
        margin-bottom: 20px;
        input {
            background-color: $accentColor;
        }
    }
    .btn {
        width: 100%;
        font-family: $secondaryBold;
        text-transform: uppercase;
        color: $secondaryColor;
        background-color: $accentColor;
        letter-spacing: 0.8px;
        font-size: 12px;
    }
}


/*  ****************  Galerie ********************/

.galerie {
    padding-left: 15px;
    padding-right: 15px;
    height: auto;
}

.gallery {
    -ms-flex: 25%;
    /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.gallery img {
    width: 100%;
    margin-top: 8px;
    vertical-align: middle;
}


/* Responsive layout - makes a two column-layout instead of four columns */

@media screen and (max-width: 800px) {
    .gallery {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}


/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 600px) {
    .gallery {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
}


/*  ****************  Cta ********************/

.cta {
    margin-top: 4px;
}

.row-cta {
    width: 100%;
}

.col-cta {
    background-color: $accentColor;
    height: 100vh;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    * {
        text-align: center;
    }
    img {
        margin-bottom: 30px;
        width: 110px;
        height: 110px;
    }
    h2 {
        font-size: $title;
        max-width: 80%;
        font-family: $primaryFont;
    }
    p {
        padding: 3vh;
    }
    a {
        background: $secondaryColor;
        font-family: $secondaryBold;
        color: $primaryColor;
        padding: 12px 70px;
        font-size: 12px;
        border-radius: 4px;
        position: relative;
        text-decoration: none;
        border-radius: 4px;
        text-transform: uppercase;
        outline: 0;
        overflow: hidden;
        z-index: 1;
        cursor: pointer;
        transition: 0.1s ease-in;
        letter-spacing: 0.8px;
        &:hover {
            color: $secondaryColor;
        }
        &:before {
            content: "";
            position: absolute;
            background: $primaryColor;
            bottom: 0;
            left: 0;
            right: 0;
            top: 100%;
            z-index: -1;
            transition: top 0.1s ease-in;
        }
        &:hover:before {
            top: 0;
        }
    }
}


/*  ****************  Footer ********************/

footer {
    background-color: $secondaryColor;
    min-height: 15vh;
    p,
    a {
        color: $primaryColor;
        height: 7.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            transition: 0.3s;
            &:hover {
                color: $primaryColor;
                transition: 0.3s;
            }
        }
    }
    .logo-footer {
        max-width: 65%;
    }
    .col-back-button {
        min-height: 15vh;
    }
    .back-button {
        width: 200px;
        background-color: $primaryColor;
        transition: 0.3s;
        &:hover {
            background-color: $secondaryColor;
            transition: 0.3s;
            path {
                fill: $primaryColor;
            }
        }
    }
}


/*  ****************  Breakpoint ********************/

@media only screen and (min-width: 361px) {
    .image-accueil img {
        width: 50%;
        height: 100vh;
    }
}

@media only screen and (max-width: 575px) {
    .gallery img {
        height: auto;
    }
    footer {
        padding-top: 5vh;
        .col-back-button {
            min-height: 10vh;
        }
        .back-button {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 576px) {
    .col-newsletter {
        .form-control::placeholder {
            text-align: left;
        }
        .input-group {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .text,
    p {
        line-height: 2.2;
        padding: 30px;
    }
}

@media only screen and (min-width: 992px) {
    section,
    .col-newsletter {
        height: 100vh;
    }
    .col-logo {
        position: relative;
        &:before {
            content: url(../img/henrietta-logo-origines.png);
            position: absolute;
            left: 0;
            top: -170px;
            width: 100%;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
    section.origines {
        height: auto;
    }
}

@media only screen and (max-width: 1024px) {
    .gallery img {
        height: 50vh;
        object-fit: cover;
    }
}

@media only screen and (min-width: 1025px) {
    .col-cta {
        height: 65vh;
        img {
            position: absolute;
            top: -55px;
        }
        h2 {
            max-width: 35%;
        }
        p {
            max-width: 50%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .origines svg {
        margin-bottom: 20vh;
    }
}

@media only screen and (min-width: 1440px) {
    .origines {
        svg {
            position: relative;
            top: 6vh;
        }
    }
    .col-cow {
        position: relative;
        &:after {
            height: 95vh;
            top: 5vh;
            left: -32vw;
            width: 89vw;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
    .col-cow {
        position: relative;
        &:after {
            height: 97vh;
            top: 3vh;
            left: -50vw;
            width: 107vw;
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
    .col-cow:after {
        width: 114vw;
    }
}